import Swiper from 'swiper';
import Component from '../../classes/Component';
import { Autoplay, Pagination } from 'swiper/modules';

const homeSlider = '.js-home-intro';

export function homeIntro() {

  return new Component({
    name: 'homeIntro',
    requiredTargets: homeSlider,
    onCreate() { },
    onInit() {
      this.homeIntroSlider = new Swiper(homeSlider, {
        modules: [Pagination, Autoplay],

        autoplay: {
          delay: 3000,
          pauseOnMouseEnter: true,
        },

        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      })
     },
    onDestroy() { }
  })
}