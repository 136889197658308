import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import variables from '../../variables';

const { get, getAll, addClass, removeClass, hasClass } = $dom;

const faqItemSelector = '.js-faq-item';
const faqItemButtonSelector = '.js-faq-item-button';

export function faqFunctional() {

  const clearClasses = () => {
    getAll(faqItemSelector).forEach(element => {
      removeClass(element, variables.classNames.active)
    });
  }

  const clickHandler = event => {
    const target = event.target.closest(faqItemButtonSelector);

    if (hasClass(target.parentNode, variables.classNames.active)) {
      removeClass(target.parentNode, variables.classNames.active);

      return;
    }

    clearClasses();
    addClass(target.parentNode, variables.classNames.active);
  }

  return new Component({
    name: 'faqFunctional',
    requiredTargets: faqItemSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('click', faqItemButtonSelector, clickHandler);
    },
    onDestroy() {
      $events.delegate
        .off('click', faqItemButtonSelector, clickHandler);
    }
  })
}