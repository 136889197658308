import gsap from 'gsap';
import Component from '../classes/Component';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { $dom } from '../helpers/dom';
import variables from '../variables';
import { $events } from '../helpers/events';
import { Offcanvas } from 'bootstrap';

const { get, getAll, addClass, removeClass, attr } = $dom;
const { active } = variables.classNames

const tabmenuSelector = '.js-tabmenu';
const tabmenuLinkSelector = '.js-tabemenu-link';
const brandsSelector = '#anker-brands';
// const howwesupportSelector = '#anker-howwesupport';
const contactsSelector = '#anker-contact';

gsap.registerPlugin(ScrollTrigger);

export function tabmenu() {

  const menuItems = getAll(tabmenuLinkSelector, tabmenuSelector)

  const clearclasses = () => {
    menuItems.forEach(element => {
      removeClass(element, active)
    });
  }

  const butrgerOpenenr = event => {
    const target = event.target.closest('.js-offcanvas-menu-opener');

    console.log(target);

    currentPage.components.tabmenu.menuOffcanvas.show();

  }

  const scrollToElement = event => {
    event.preventDefault();

    const link = event.target.closest('.js-offcanvas-menu-link');

    const elementId = attr(link, 'data-href');

    currentPage.components.tabmenu.menuOffcanvas.hide();

    scrollTo({
      top: get(elementId).offsetTop,
    })

  }

  ScrollTrigger.create({
    trigger: brandsSelector,
    // markers: true,
    start: "-=50 top",

    onEnter: () => {

      addClass(menuItems[0], active)
    },

    onEnterBack: () => {
      addClass(menuItems[0], active)
    },

    onLeaveBack: () => {
      clearclasses();
    },

    onLeave: () => {
      clearclasses();
    }
  })

  ScrollTrigger.create({
    trigger: contactsSelector,
    // markers: true,
    start: "-=50 top",

    onEnter: () => {
      addClass(menuItems[1], active)
    },

    onEnterBack: () => {
      addClass(menuItems[1], active)
    },

    onLeaveBack: () => {
      clearclasses();
    },

    onLeave: () => {
      clearclasses();
    }
  })

  return new Component({
    name: 'tabmenu',
    requiredTargets: tabmenuSelector,
    onCreate() { },
    onInit() {

      this.menuOffcanvas = new Offcanvas('#js-offcanvas-menu');

      $events.add('scroll', window, () => {
        const yScroll = window.pageYOffset;

        if (yScroll >= 500) {
          addClass(tabmenuSelector, active);
        } else {
          removeClass(tabmenuSelector, active);
        }

        if (yScroll >= 10) {
          addClass('.js-header', active);
        } else {
          removeClass('.js-header', active);
        }
      })

      $events.add('shown.bs.offcanvas', window, () => {
        addClass(menuItems[3], active);
      })

      $events.add('hidden.bs.offcanvas', window, () => {
        removeClass(menuItems[3], active);

      })

      $events.delegate
        .on('click', '.js-offcanvas-menu-link', scrollToElement)
        .on('click', '.js-offcanvas-menu-opener', butrgerOpenenr)
    },
    onDestroy() {

      this.menuOffcanvas = null;

      $events.remove('scroll', window, () => {
        const yScroll = window.pageYOffset;

        if (yScroll >= 500) {
          addClass(tabmenuSelector, active);
        } else {
          removeClass(tabmenuSelector, active);
        }
      })

      $events.remove('shown.bs.offcanvas', window, () => {
        addClass(menuItems[3], active);
      })

      $events.remove('hidden.bs.offcanvas', window, () => {
        removeClass(menuItems[3], active);

      })

      $events.delegate
        .off('click', '.js-offcanvas-menu-link', scrollToElement)
        .off('click', '.js-offcanvas-menu-opener', butrgerOpenenr)
    }
  })
}