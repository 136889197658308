import Page from '../../classes/Page';
import { faqFunctional } from './faqFunctional';
import { homeIntro } from './homeIntro';

const homePage = new Page({
  name: 'home',
  rootElementId: 'js-page-home',

  onCreate() {
  },

  onInit() {

    this
      .addComponent(homeIntro)
      .addComponent(faqFunctional)
  },

  onDestroy() {
  },
});

export default homePage;
